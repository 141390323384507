import {extendComponent} from "juis-components/ComponentUtils.js";
import Container from "juis-components/Container.js";
import Property from "juis-components/Property.js";
import Icon, {createIcon} from "./Icon.js";
import Divider from "./Divider.js";
import Link from "./Link.js";
import BULMA from "./bulmaCssClasses.js";
import BULMA_CSS_CLASSES from "./bulmaCssClasses.js";
import HTML from "../utils/HTML.js";
import TextComponent from "../components/TextComponent.js";

function getTrigger(text, icon, ...cssClasses) {
    return new Container(function () {
        if (text) {
            this.text = new TextComponent(text, HTML.SPAN, [BULMA_CSS_CLASSES.IS_HIDDEN_MOBILE]);
            this.icon2 = new Icon(function () {
                this.icon = "ellipsis-h";
            }, [BULMA_CSS_CLASSES.IS_HIDDEN_TABLET]);
        }
        this.icon = new Icon(function () {
            this.icon = icon;
        });
        this.registerDomEvents("click");
        this.preventDefaultDomEvent("click");
        this.getNode().setAttribute("tabindex", 0);
    }, [BULMA.IS_MARGINLESS, BULMA.BUTTON, ...cssClasses], HTML.BUTTON);
}

function getMenu() {
    return new Container(function () {
        this.clear = () => this.dropdownContent.destroyAllChildren();
        this.getNode().setAttribute("role", "menu");
        this.dropdownContent = new Container(function () {

        });
    }, [BULMA.DROPDOWN.MENU, BULMA.DROPDOWN.CONTENT]);
}

function getLink(text, href, cssClassNames) {
    let link = new Link();
    link.text = text;
    link.url = href;
    link.getNode().addCssClass(BULMA.DROPDOWN.ITEM);
    link.getNode().addCssClass(BULMA.IS_UNSELECTABLE);
    if (cssClassNames) {
        cssClassNames.forEach(className => link.getNode().addCssClass(className));
    }
    return link;
}

function getDisabledLink(text) {
    const cssClasses = [BULMA.DROPDOWN.ITEM, BULMA.TYPOGRAPHY.HAS_TEXT_GREY_LIGHTER, BULMA.IS_UNSELECTABLE];
    return new TextComponent(text, HTML.DIV, cssClasses);
}

export default extendComponent(Container, function () {
    const angleUp = "angle-up";
    const angleDown = "angle-down";
    let trigger = getTrigger(undefined, "angle-down");
    this.addTriggerCss = cssClass => trigger.getNode().addCssClass(cssClass);
    let menu = getMenu();
    this.appendChild(trigger);
    this.appendChild(menu);
    this.addControl = (control) => this.insertBefore(control, trigger);
    this.addLink = function (text, href, cssClassNames) {
        menu.dropdownContent.appendChild(getLink(text, href, cssClassNames));
    };
    this.clear = () => menu.clear();
    this.addItem = function (item, key, settings) {
        if (typeof item === "string") {
            if (settings && (settings.icon || settings.svgIcon)) {
                item = new Container(function () {
                    this.icon = createIcon(settings.icon, ...settings.iconClasses);
                    if (settings.svgIcon) {
                        this.icon.getNode().getActualElement().innerHTML = settings.svgIcon;
                    }
                    this.icon.getNode().setStyle("margin-right", "0.5em");
                    this.text = new TextComponent(item, HTML.SPAN, [BULMA.IS_UNSELECTABLE]);
                }, [BULMA.IS_UNSELECTABLE]);
            } else {
                item = new TextComponent(item, HTML.DIV, [BULMA.IS_UNSELECTABLE]);
            }
            item.getNode().setStyle("white-space", "nowrap");
        }
        item.getNode().addCssClass(BULMA.DROPDOWN.ITEM);
        item.registerDomEvents("click");
        item.on("click", () => {
            if (item.disabled) {
                return;
            }
            this.trigger("select", {item, key, settings});
        });
        return menu.dropdownContent.appendChild(item);
    };
    this.addDisabledLink = function (text) {
        menu.dropdownContent.appendChild(getDisabledLink(text));
    };
    this.addDivider = function () {
        let divider = new Divider(() => {
        }, BULMA.DROPDOWN.DIVIDER);
        menu.dropdownContent.appendChild(divider);
    };
    this.isLoading = new Property(isLoading => {
        if (isLoading) {
            trigger.getNode().addCssClass(BULMA.IS_LOADING);
        } else {
            trigger.getNode().removeCssClass(BULMA.IS_LOADING);
        }
    });

    this.text = new Property(text => {
        let cssClasses = this.isSmall ? [BULMA.IS_SMALL] : [];
        let newTrigger = getTrigger(text, this.isUp ? angleUp : angleDown, ...cssClasses);
        this.replaceChild(newTrigger, trigger);
        trigger = newTrigger;
    });
    this.isUp = new Property(isUp => {
        let cssClasses = this.isSmall ? [BULMA.IS_SMALL] : [];
        let newTrigger = getTrigger(this.text, isUp ? angleUp : angleDown, ...cssClasses);
        this.replaceChild(newTrigger, trigger);
        trigger = newTrigger;
        if (isUp) {
            this.getNode().addCssClass(BULMA.IS_UP);
        } else {
            this.getNode().removeCssClass(BULMA.IS_UP);
        }
    });
    this.isSmall = new Property(isSmall => {
        let cssClasses = isSmall ? [BULMA.IS_SMALL] : [];
        let newTrigger = getTrigger(this.text, this.isUp ? angleUp : angleDown, ...cssClasses);
        this.replaceChild(newTrigger, trigger);
        trigger = newTrigger;
    });
}, [BULMA.DROPDOWN.DROPDOWN]);
