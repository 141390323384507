import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {
    createTextAreaEditor,
    getDropDownFactory,
    iconPickerFactory
} from "../../components/fieldEditors/editorFactories.js";
import StockProductList from "./StockProductList.js";
import BillItem from "./BillItem.js";
import {fieldDropdownSettings, getEditorFields, getSecondLevelFields} from "../../utils/fieldUtils.js";
import Bill from "./Bill.js";
import BillList from "./BillList.js";
import {createLink} from "../../bulma_components/Link.js";
import BankAccount from "./BankAccount.js";
import App from "./App.js";
import BillTemplate from "./BillTemplate.js";
import {getLocaleDropdownFactory} from "../translations/localeDropdownFactory.js";
import EditorCard from "../../components/table-view/EditorCard.js";
import Filter from "../../utils/Filter.js";
import ContactGroup from "./ContactGroup.js";
import TextComponent from "../../components/TextComponent.js";
import setDefaults from "./utils/setDefaults.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import billTypeLexicons from "./lexicons/bill-type/billTypeLexicons.js";
import LOCALES from "../translations/locales.js";
import localeCellFactory from "../translations/localeCellFactory.js";
import BillType from "./BillType.js";
import BillEditorTab from "./BillEditorTab.js";
import BillTypePermission from "./BillTypePermission";
import BillTypeProductSearchField from "./BillTypeProductSearchField";
import ProductGroup from "./ProductGroup";

export default new Model(function () {
    this.app = new Field(App);
    this.code = new Field()
        .asReadOnly()
        .asString()
        .withLabel("Url Code");

    this.name = new Field()
        .asTranslatableString()
        .withLabel("Name");

    this.nextNumber = new Field()
        .asNumber()
        .withLabel("Next number");

    this.penaltyInterest = new Field()
        .asNumber(0, 100, 0.1)
        .withLabel("Penalty interest %");

    this.allowMultipleOpenBills = new Field()
        .asBoolean()
        .withLabel("Allow multiple open bills");

    this.stockPicker = new Field(StockProductList).withLabel("Stock picker");

    this.billEditorFields = new Field()
        .asEnumList(getEditorFields(Bill))
        .withEditorFactory(getDropDownFactory({
                ...fieldDropdownSettings, itemFactory: value => new TextComponent(Bill[value].getLabel())
            }),
        )
        .withCellFactory(fields => fields.map(fieldName => Bill[fieldName].getLabel()).join(", "))
        .withLabel("Bill editor");

    this.billReceiverFields = new Field()
        .asEnumList([
            "recipient",
            "recipientName",
            "recipientStreet",
            "recipientStreet2",
            "recipientZipCode",
            "recipientCity",
            "recipientCountry",
            "recipientFinvoiceIdentifier",
            "recipientFinvoiceIntermediator",
            "recipientBusinessId",
            "recipientVatNumber"
        ])
        .withEditorFactory(getDropDownFactory({itemFactory: (fieldName) => Bill[fieldName].getLabel()}))
        .withCellFactory(fields => fields.map(fieldName => Bill[fieldName].getLabel()).join(", "))
        .withLabel("Recipient editor");

    this.billItemEditorFields = new Field()
        .asEnumList(getEditorFields(BillItem))
        .withEditorFactory(getDropDownFactory({
                ...fieldDropdownSettings, itemFactory: value => new TextComponent(BillItem[value].getLabel())
            }),
        )
        .withCellFactory(fields => fields.map(fieldName => BillItem[fieldName].getLabel()).join(", "))
        .withLabel("Bill item editor");

    this.billItemColumns = new Field()
        .asEnumList(getSecondLevelFields(BillItem))
        .withEditorFactory(getDropDownFactory({
                ...fieldDropdownSettings, itemFactory: value => new TextComponent(BillItem[value].getLabel())
            }),
        )
        .withCellFactory(fields => fields.map(fieldName => BillItem[fieldName].getLabel()).join(", "))
        .withLabel("Bill item columns");

    this.billList = new Field(BillList).withLabel("Bill list");
    this.icon = new Field().asString().withLabel("Icon").withEditorFactory(iconPickerFactory);

    this.accounts = new Field([BankAccount])
        .withCreatorFactory((billType) => new EditorCard(function () {
            this.setModel(BankAccount);
            this.title = linguist.t("createBankAccount");
            this.defaultProperties = {app: billType.app};
            this.addField(BankAccount.name);
            this.addField(BankAccount.iban);
            this.addField(BankAccount.bic);
            this.refresh();
        }))
        .withLabel("(Bank) Accounts");

    this.automaticPayment = new Field(BankAccount).withLabel("Auto-payment");

    this.paymentTerm = new Field()
        .withOptions(Object.keys(Bill.PAYMENT_TERMS))
        .withEditorFactory(getDropDownFactory({
            itemFactory: (paymentTerm) => new TextComponent(Bill.PAYMENT_TERMS[paymentTerm])
        }))
        .withCellFactory((paymentTerm) => new TextComponent(Bill.PAYMENT_TERMS[paymentTerm]))
        .withLabel("Payment term");

    this.REFERENCE_TYPES = {
        "FINNISH": "Finnish",
        "RF_CREDITOR_REFERENCE": "RF Creditor Reference",
        "LUHN": "Luhn",
        "LUHN_WITH_LENGTH_DIGIT": "Luhn with length digit",
    };
    this.referenceType = new Field()
        .withOptions(Object.keys(this.REFERENCE_TYPES))
        .withEditorFactory(getDropDownFactory({
            itemFactory: (referenceType) => new TextComponent(this.REFERENCE_TYPES[referenceType])
        }))
        .withCellFactory((referenceType) => new TextComponent(this.REFERENCE_TYPES[referenceType]))
        .withLabel("Reference type");

    this.moveDueDateToNextWeekday = new Field()
        .asBoolean()
        .withLabel("Adjust due date to weekdays");

    this.needsConfiguration = new Field()
        .asBoolean()
        .withLabel("Needs configuration");

    this.recipientOfPayment = new Field()
        .asString()
        .withLabel("Recipient of payment")
        .withEditorFactory(createTextAreaEditor);

    this.peppolSenderId = new Field()
        .asString()
        .withLabel("Peppol Id");

    this.defaultLocale = new Field().asString()
        .withLabel("Default language")
        .withOptions(Object.keys(LOCALES))
        .withCellFactory(localeCellFactory)
        .withEditorFactory(getLocaleDropdownFactory());
    this.logo = new Field().asImage().withLabel("Logo");
    this.header1 = new Field().asString().withLabel("Header text 1");
    this.header2 = new Field().asString().withLabel("Header text 2");
    this.footer1 = new Field().asString().withLabel("Footer text 1").withHelp("Free text to show in the footer of bills. Can be left empty.");
    this.footer2 = new Field().asString().withLabel("Footer text 2").withHelp("Free text to show in the footer of bills. Can be left empty.");
    this.footer3 = new Field().asString().withLabel("Footer text 3");
    this.footer4 = new Field().asString().withLabel("Footer text 4");
    this.footer5 = new Field().asString().withLabel("Footer text 5");
    this.footer6 = new Field().asString().withLabel("Footer text 6");
    this.footer7 = new Field().asString().withLabel("Footer text 7");
    this.footer8 = new Field().asString().withLabel("Footer text 8");
    this.footer9 = new Field().asString().withLabel("Footer text 9");
    this.footer10 = new Field().asString().withLabel("Footer text 10");
    this.footer11 = new Field().asString().withLabel("Footer text 11");
    this.footer12 = new Field().asString().withLabel("Footer text 12");
    this.requiresPayment = new Field().asBoolean().withLabel("Requires payment");
    this.canExportFinvoice = new Field().asBoolean().withLabel("Can export Finvoice");
    this.finvoiceIdentifier = new Field().asString().withLabel("Finvoice Identifier");
    this.finvoiceIntermediator = new Field().asString().withLabel("Finvoice Intermediator");
    this.canNullify = new Field().asBoolean().withLabel("Can nullify");
    this.canShowReminder = new Field().asBoolean().withLabel("Has reminders");
    this.includeInSalesReport = new Field().asBoolean().withLabel("In Sales Report");
    this.billTemplate = new Field(BillTemplate).withLabel("Template");
    this.billTemplateOptions = new Field([BillTemplate]).withLabel("Template options")
        .withHelp("If set, the user can change the template of specific bills to one of the specified values.");

    this.canCopyToOtherTypes = new Field([this]).withLabel("Copy to other bill types");

    this.canConvertToOtherType = new Field(this).withLabel("Convert to other bill type");
    this.billSettingsFields = new Field()
        .asEnumList(getEditorFields(this))
        .withCellFactory(fields => fields.map(fieldName => BillType[fieldName].getLabel()).join(", "))
        .withEditorFactory(getDropDownFactory({
                itemFactory: value => new TextComponent(BillType[value].getLabel())
            }),
        )
        .withLabel("Bill settings Fields");
    this.recipients = new Field([ContactGroup]).withLabel("Recipients");
    this.emailSubjectTemplate = new Field().asString().withLabel("Email subject");
    this.emailBodyTemplate = new Field().asString().withLabel("Email body").withEditorFactory(createTextAreaEditor);

    this.reminderEmailSubjectTemplate = new Field().asString().withLabel("Reminder email subject");
    this.reminderEmailBodyTemplate = new Field().asString().withLabel("Reminder email body").withEditorFactory(createTextAreaEditor);
    this.editorTabs = new Field([BillEditorTab]).asCascading();
    this.currencyCode = new Field().asCurrency();
    this.hasFileAttachments = new Field().asBoolean().withLabel("Enable attachments");
    this.purchase = new Field().asBoolean().withLabel("Is Purchase");
    this.permissions = new Field([BillTypePermission]).withLabel("Permissions");
    this.productSearchFields = new Field([BillTypeProductSearchField]).asCascading().withLabel("Search Fields");
    this.productGroups = new Field([ProductGroup]).withLabel("Product Groups");
    this.makeTranslatable();

    this.on(this.EVENTS.BEFORE_SAVE, (entity) => {
        if (!entity.hasDirtyField(this.needsConfiguration)) {
            entity.needsConfiguration = false;
        }
    });

    this.canCreateNewBill = function () {
        if (this.allowMultipleOpenBills) {
            return Promise.resolve(true);
        }
        return Bill.getOptional(Filter.and(Filter.eq(Bill.locked, false), Filter.eq(Bill.type, this)))
            .map(bill => !bill)
            .orElseGetPromise(() => true);
    };
    this.getBlockingBill = function () {
        if (this.allowMultipleOpenBills) {
            return Promise.resolve(null);
        }
        return Bill.getOptional(Filter.and(Filter.eq(Bill.locked, false), Filter.eq(Bill.type, this)))
            .orElseGetPromise(() => null);
    };


    this.createBill = function (billProperties) {
        let bill = new Bill(billProperties);
        bill.makeDirty();
        bill.type = this;
        bill.locale = this.defaultLocale;
        bill.app = this.app;
        return bill;
    };
    this.excludeFromCachedDescendants();
    setDefaults(this, {
        defaultField: this.name,
        linkFactory: billType => createLink(`/${billType.app.code}/bill-types/${billType.code}/settings/`, BillType.name.getTranslatedValueFor(billType))
    });
    const linguist = new Linguist(billTypeLexicons).withAudience(this);
}, "BillType", "bill-types");
