import Field from "./Field.js";
import {NOT_FOUND} from "juis-commons/Errors.js";
import {camelCaseToDash} from "juis-commons/JuisUtils.js";
import Filter from "./Filter.js";

let versionCallback = () => {
};

const downloadBlob = (blob, filename) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
    } else {
        let file = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = file;
        link.download = filename;
        link.click();

        // For Firefox, it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
            window.URL.revokeObjectURL(file);
        }, 250);
    }
};

const generateHeadersForListRequest = (options) => {
    let {app, maxRows = 15, firstRow = 0, orderBy = "id", filter, lastPageMaxRows = 0} = options;
    let orderByHeader = "id";
    if (typeof orderBy === "string") {
        orderByHeader = orderBy;
    } else if (orderBy instanceof Field) {
        orderByHeader = orderBy.getName();
    }
    const headers = {
        "Order-By": orderByHeader,
        "Max-Rows": maxRows.toString(10),
        "First-Row": firstRow.toString(10)
    };
    if (app) {
        headers["App-Id"] = app.id;
    }
    if (filter) {
        if (filter === Filter.false) {
            console.error("Tried requesting with Filter.false which would return nothing");
        } else if (filter !== Filter.true) {
            headers["Where"] = filter.toString();
        }
    }
    if (lastPageMaxRows) {
        headers["Last-Page-Max-Rows"] = lastPageMaxRows.toString(10);
    }
    if (options.aggregations && Object.values(options.aggregations).length > 0) {
        headers["aggregations"] = JSON.stringify(Object.values(options.aggregations));
    }
    if (options.suggestionFilter) {
        headers["Suggestion-Filter"] = JSON.stringify(options.suggestionFilter);
    }
    return {...headers, ...options.headers};
};

function resolveEmptyResponse(response) {
    return response.text().then(text => {
        if (text !== "") {
            throw new Error("Missing content type");
        } else {
            response.text = () => Promise.resolve("{}");
            return response;
        }
    });
}

function checkResponseHeaders(response) {
    const contentType = response.headers.get("Content-Type");
    if (contentType === null && response.status >= 200 && response.status < 300) {
        return resolveEmptyResponse(response);
    } else if (contentType !== "application/json") {
        let error = new Error(`Unexpected content type: ${contentType}.`);
        if (response.status >= 400) {
            error = new Error(`Request failed. Response status ${response.status}.`);
        }
        error.response = response;
        error.status = response.status;
        throw error;
    }
    versionCallback(response.headers.get("version"));
    return response;
}

function checkForError(response) {
    if (response.status >= 400) {
        return response.json().then(body => {
            let error;
            if (response.status === 404) {
                error = new Error(NOT_FOUND);
            } else if (response.status === 403) {
                error = new Error("Sorry, it seems you don't have the needed permissions to complete this operation.");
            } else if (body.error) {
                if (body.error.id) {
                    error = new Error("Request failed. An error was logged with the following Id: " + body.error.id);
                } else if (typeof body.error === "string") {
                    error = new Error(body.error);
                }
            } else if (!error) {
                error = new Error(`Request failed. Response status ${response.status}.`);
            }
            error.response = response;
            error.status = response.status;
            throw error;
        });
    }
    return response;
}

function getRestApiUrl(...urlParts) {
    return urlParts.reduce((urlBeginning, urlPart) => {
        switch (typeof urlPart) {
            case "string":
                return urlBeginning + "/" + camelCaseToDash(urlPart);
            case "number":
                return urlBeginning + "/" + urlPart;
            default:
                throw new Error("Cannot handle url part: " + urlPart);
        }
    }, "/api");
}

const defaultHeaders = {
    "Content-Type": "application/json",
    "frontend-version": HEWECON_GLOBAL.frontendVersion
};

/**
 *
 * @param additionalHeaders {{}.<string, string>}
 * @returns {Headers}
 */
const getHeaders = (additionalHeaders) => {
    let headers = new Headers();
    Object.entries(defaultHeaders).forEach(entry => headers.append(entry[0], encodeURI(entry[1])));
    Object.entries(additionalHeaders).forEach(entry => headers.append(entry[0], encodeURI(entry[1])));
    return headers;
};

function wait(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
}


let RestServer = {
    fetch(url, fetchOptions = {}, delay = 1000, tries = 3) {
        function onError(error) {
            const triesLeft = tries - 1;
            if (!triesLeft <= 0) {
                throw error;
            }
            return wait(delay).then(() => this.fetch(url, fetchOptions, delay, triesLeft));
        }

        if (navigator.onLine === false) {
            return new Promise(resolve => window.addEventListener("online", resolve, {once: true}))
                .then(() => fetch(url, fetchOptions).catch(onError));
        }
        return fetch(url, fetchOptions).catch(onError);
    },
    getById(url, id, headers = {}) {
        headers = getHeaders(headers);
        return this.fetch(getRestApiUrl(url, id), {headers})
            .catch(e => {
                e.message = `Error fetching ${getRestApiUrl(url, id)} with headers ${JSON.stringify(headers)}. Original error: ${e.message}`;
                throw e;
            })
            .then(checkResponseHeaders)
            .then(checkForError)
            .then(response => response.json());
    },
    getOwn(url, appCode, headers = {}) {
        headers = getHeaders(headers);
        return this.fetch(getRestApiUrl(url, "own", appCode), {headers})
            .then(checkResponseHeaders)
            .then(checkForError)
            .then(response => response.json());
    },
    get(url, headers = {}) {
        headers = getHeaders(headers);
        return this.fetch(getRestApiUrl(url), {headers})
            .then(checkResponseHeaders)
            .then(checkForError)
            .then(response => response.json());
    },
    post(url, obj, headers = {}) {
        headers = getHeaders(headers);
        let method = "POST";
        let body = JSON.stringify(obj);
        return this.fetch(getRestApiUrl(url), {method, body, headers})
            .then(checkResponseHeaders)
            .then(checkForError)
            .then(response => response.json());
    },
    patch(url, id, dirtyProperties, headers = {}) {
        headers = getHeaders(headers);
        let method = "PATCH";
        let body = JSON.stringify(dirtyProperties);
        return this.fetch(getRestApiUrl(url, id), {method, body, headers})
            .then(checkResponseHeaders)
            .then(checkForError)
            .then(response => response.json());
    },
    delete(url, id, headers = {}) {
        headers = getHeaders(headers);
        let method = "DELETE";
        return this.fetch(getRestApiUrl(url, id), {method, headers})
            .then(checkResponseHeaders)
            .then(checkForError)
            .then(response => response.json());
    },
    download(url, headers = {}) {
        headers = getHeaders(headers);
        let method = "GET";
        return this.fetch(getRestApiUrl(url), {method, headers})
            .then(response => {
                if (response.status >= 400) {
                    throw new Error(`Request failed. Response status ${response.status}.`);
                }
                response.blob().then(blob => {
                    let contentDisposition = response.headers.get("content-disposition");
                    let filename = contentDisposition.substr(contentDisposition.indexOf("=") + 1);
                    return downloadBlob(blob, filename);
                });
            });
    },
    upload(url, file) {
        let formData = new FormData();
        formData.append("file", file);
        return this.fetch(url, {method: "POST", body: formData})
            .then(checkResponseHeaders)
            .then(checkForError)
            .then(response => response.json());
    },
    setVersionCallback: (callback) => versionCallback = callback,
};

export {RestServer as default, generateHeadersForListRequest, downloadBlob, getRestApiUrl};
