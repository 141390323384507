import Model from "../../utils/RestModel.js";
import Field from "../../utils/Field.js";
import {getEditorFields} from "../../utils/fieldUtils.js";
import BillType from "./BillType.js";
import {
    createTranslatableMultiLineEditor,
    getDropDownFactory,
    getVatPercentageEditorFactory
} from "../../components/fieldEditors/editorFactories.js";
import ProductView from "./ProductView.js";
import ProductList from "./ProductList.js";
import PhoneNumber from "../../components/PhoneNumber.js";
import StockProductList from "./StockProductList.js";
import TextComponent from "../../components/TextComponent.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import appLexicons from "./lexicons/app/appLexicons.js";
import {imageThumbnailCellFactory} from "../../components/ImageThumbnail.js";
import AppSettings from "./AppSettings.js";
import ContactGroup from "./ContactGroup";
import VatGroup from "./VatGroup";
import {countryCodes, getRegionName} from "../../utils/IntlUtils";
import {businessIdValidation, vatNumberValidation} from "../utils/businessIdUtils";

export default new Model(function () {
    this.code = new Field().asString().withLabel("Url Code");
    this.name = new Field().asTranslatableString().withLabel("Name")
        .withHelp("We recommend naming the app after your company or organisation.");
    this.description = new Field().asTranslatableString().withLabel("Description").withEditorFactory(createTranslatableMultiLineEditor);
    this.loginText = new Field().asTranslatableString().withLabel("Login text").withEditorFactory(createTranslatableMultiLineEditor);
    this.archived = new Field().asBoolean().withLabel("Is Archived");
    this.template = new Field().asBoolean().withLabel("Template");
    this.demo = new Field().asBoolean().withLabel("Demo");
    this.public = new Field().asBoolean().withLabel("Is Public");
    this.owner = new Field().asString().asReadOnly().withLabel("Owner");
    this.pricesIncludeVat = new Field().asBoolean().withLabel("Prices include VAT");
    this.defaultVat = new Field(VatGroup).withEditorFactory(getVatPercentageEditorFactory(this.pricesIncludeVat));
    this.currencyCode = new Field().asCurrency();
    this.monthlyPrice = new Field()
        .asMoney("EUR")
        .withLabel("Price / month")
        .withHelp("Finnish VAT, 24%, will be added to the listed prices.");
    this.billSettingsColumns = new Field().asEnumList(getEditorFields(BillType)).withLabel("Bill settings Columns");
    this.canCreateBillTypes = new Field().asBoolean().withLabel("Can create bill types");
    this.defaultBillType = new Field(BillType).withLabel("Default bill type");
    this.productList = new Field(ProductList).withLabel("Default product list");
    this.productView = new Field(ProductView).withLabel("Default product view");
    this.defaultStockList = new Field(StockProductList).withLabel("Default stock list");
    this.showEmptyStockProducts = new Field().asBoolean().withLabel("Show empty stocks");
    this.suppliers = new Field(ContactGroup).withLabel("Suppliers");

    // Contact details
    this.contactName = new Field().asString().withLabel("Name");
    this.street = new Field().asString().withLabel("Street");
    this.street2 = new Field().asString().withLabel("Street 2");
    this.zipCode = new Field().asString().withLabel("Zip Code");
    this.city = new Field().asString().withLabel("City");
    this.state = new Field().asString().withLabel("State");
    this.country = new Field().asString().withOptions(countryCodes).withLabel("Country")
        .withEditorFactory(getDropDownFactory({itemFactory: (country) => new TextComponent(getRegionName(country))}))
        .withCellFactory((country) => new TextComponent(getRegionName(country)));
    this.phoneNumber = new Field().asString().withLabel("Phone").withCellFactory(value => new PhoneNumber(value));
    this.email = new Field().asString().withLabel("E-mail");
    this.website = new Field().asString().withLabel("Website");
    this.businessId = new Field().asString().withLabel("Business ID").withValidationRule(businessIdValidation);
    this.vatNumber = new Field().asString().withLabel("VAT number").withValidationRule(vatNumberValidation);
    this.dropboxAccessToken = new Field().asString().withLabel("Dropbox access token");


    this.logo = new Field().asImage().withLabel("Logo").withHelp("Logo is shown in the top nav bar");
    this.footer = new Field().asImage().withLabel("Footer").withHelp("A footer image that is shown on the bottom of all views in this app");
    this.loginBackground = new Field().asImage().withLabel("Login Background").withHelp("Background image in the login screen");
    this.favicon = new Field().asFile().withLabel("Favicon").withCellFactory(imageThumbnailCellFactory).withHelp("Favicons are shown on the browser tab");
    this.svgIcon = new Field().asFile().withLabel("SVG Icon").withCellFactory(imageThumbnailCellFactory).withHelp("The icon is used to generate icons of different sizes for e.g. splash screen and home screen");
    this.cssOverride = new Field().asFile().withLabel("Style sheet").withHelp("A style sheet that will be used when this app is loaded");
    this.badge = new Field().asFile().withLabel("Badge").withCellFactory(imageThumbnailCellFactory).withHelp("96px * 96px grayscale transparent png image shown e.g on notifications");

    // Permissions
    this.employeeTicketRead = new Field().asEnum(["ALL", "OWN", "NONE"]).withDefaultValueCallback(() => "NONE");
    this.employeeTicketWrite = new Field().asEnum(["ALL", "OWN", "NONE"]).withDefaultValueCallback(() => "NONE");
    this.employeeStockRead = new Field().asEnum(["ALL", "OWN", "NONE"]).withDefaultValueCallback(() => "NONE");
    this.employeeStockWrite = new Field().asEnum(["ALL", "OWN", "NONE"]).withDefaultValueCallback(() => "NONE");

    this.settings = new Field(AppSettings);

    this.ifExists = (appCode) => {
        let thenCallbacks = [];
        let elseCallbacks = [];
        let obj = {
            then: (newCallback) => {
                thenCallbacks.push(newCallback);
                return obj;
            },
            else: (newCallback) => {
                elseCallbacks.push(newCallback);
                return obj;
            }
        };
        this.fetchCustomEndpoint("exists", appCode).then((response) => {
            if (response.data) {
                thenCallbacks.forEach(callback => callback(response.data));
            } else {
                elseCallbacks.forEach(callback => callback.call());
            }
        });
        return obj;
    };
    this.getCurrent = () => {
        const appCodeFromUrl = location.href.split("/")[3];
        return this.cache.getByCode(appCodeFromUrl);
    };
    this.getAllPublic = () =>
        this.fetchCustomEndpoint("public").then((response) =>
            response.data.map(datum => new this(datum)));
    new Linguist(appLexicons).withAudience(this);
    this.makeTranslatable();
}, "App", "apps");
