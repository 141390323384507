export default class ValidationError extends Error {
    constructor(message, field, entity) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message);
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError);
        }
        this.name = "Validation error";
    }
}